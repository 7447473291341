var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"candidate-vote-card border",class:{
    'bg-catskill-white': _vm.isFrom,
    'bg-apple-green border-ocean-green': !_vm.isFrom,
  }},[_c('div',{staticClass:"verti w-full items-center bg-white px-2 py-5 my-8 rounded-lg overflow-x-auto"},[_c('span',{staticClass:"text-xl font-bold"},[_vm._v(_vm._s(_vm.isFrom ? this.$translate('components.candidateVote.detailsCard.from') : this.$translate('components.candidateVote.detailsCard.to')))]),_c('div',{staticClass:"horiz w-full py-5 items-center-center px-3 rounded-lg mt-2 text-xl border",class:{
        'bg-catskill-white': _vm.isFrom,
        'bg-apple-green border-ocean-green': !_vm.isFrom,
      }},[_c('span',{staticClass:"mr-2 text-regent-gray"},[_vm._v(_vm._s(_vm.candidate.order))]),(_vm.candidate.imageUrl)?_c('img',{staticClass:"w-8 h-8 mr-2",attrs:{"alt":_vm.candidate.name,"src":_vm.candidate.imageUrl}}):_vm._e(),_c('span',{staticClass:"font-medium mr-2"},[_vm._v(_vm._s(_vm.candidate.name))]),(_vm.isCurrentCandidateVoted)?_c('img',{staticClass:"w-7 h-7",attrs:{"alt":_vm.$translate('components.candidateVote.detailsCard.alreadyVotingAlt'),"src":require("@/assets/img/square-selected.svg")}}):_vm._e()])]),_c('div',{staticClass:"verti justify-center"},[_c('div',{staticClass:"horiz items-center"},[_c('img',{staticClass:"object-contain w-7 h-7 md:w-9 md:h-9",attrs:{"alt":_vm.$translate('components.candidateVote.detailsCard.altIcon'),"src":require("@/assets/img/gas_rewards_icon.svg")}}),_c('div',{staticClass:"font-bold ml-2 text-xl md:text-2xl"},[_vm._v(" "+_vm._s(_vm.$translate('components.candidateVote.detailsCard.title'))+" ")])]),_c('div',{staticClass:"flex justify-end"},[_c('div',{staticClass:"text-center d-flex ma-4 justify-center mb-2"},[_c('tooltip',{attrs:{"label":_vm.$translate('components.candidateVote.detailsCard.estimated'),"text":_vm.$translate(
              'components.candidateVote.detailsCard.rewardAverageTooltip'
            )},on:{"onMobileOpen":_vm.setIsTooltipMobileOpen}})],1)]),_c('tooltip-mobile-content',{attrs:{"show":_vm.isTooltipMobileOpen,"text":_vm.$translate(
          'components.candidateVote.detailsCard.rewardAverageTooltip'
        ),"container-class":"bg-pale-greenish-blue"}}),_c('gas-calculator-table',{staticClass:"mt-5",attrs:{"daily-amount":_vm.dailyAmount,"gas-price-in-dollar":_vm.gasCurrentPriceInDollar,"monthly-amount":_vm.monthlyAmount,"weekly-amount":_vm.weeklyAmount,"yearly-amount":_vm.yearlyAmount}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }